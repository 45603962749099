//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Null } from '@/components';
export default {
  components: {
    Null,
  },
  data() {
    return {
      list: [],
      goods: {},
    };
  },
  created() {
    this.getInit();
  },
  computed: {
    pages() {
      return this.$store.state.page.pageInfo;
    },
    configInfo() {
      return this.$store.state.base.configInfo;
    },
  },
  methods: {
    async getInit() {
      this.list = this.pages.List;
      if (this.isExist(this.list)) {
        this.goods = this.list.find((item) => item.name == 'Goods');
      }
      document.title = this.configInfo.storeName;
    },
  },
};
